import styled from 'styled-components';

import vars from 'variables';

import up from 'assets/Common/Roadmap/Scheme/up.svg';
import down from 'assets/Common/Roadmap/Scheme/down.svg';

const Root = styled.div`
  .headers {
    margin-top: 110px;
    margin-left: 75px;
    margin-bottom: 10px;
    display: flex;
  }

  .headers strong {
    flex: 1;
    text-align: center;
    text-transform: uppercase;
  }

  ${vars.desktop.mediaQuery} {
    .scheme,
    .phases {
      display: flex;
      flex: 1;
    }

    .scheme h3 {
      padding: 35px 0;
      font-size: 0.9rem;
      letter-spacing: 1px;
      line-height: 65px;
      text-align: center;
      writing-mode: vertical-lr;
    }

    .scheme h3.contract-innovation {
      background: rgb(0, 152, 255);
    }

    .scheme h3.staking-and-farming {
      background: rgb(0, 135, 255);
    }

    .scheme h3.exchange-listing {
      background: rgb(0, 119, 255);
    }

    .scheme h3.partnerships {
      background: rgb(0, 101, 255);
    }

    .scheme h3.products {
      background: rgb(0, 82, 255);
    }

    .scheme h3.nfts {
      background: rgb(0, 62, 255);
    }

    .scheme h3.games {
      background: rgb(0, 56, 226);
    }

    .scheme h3.betting {
      background: rgb(0, 82, 255);
    }

    .scheme h3.legal {
      background: rgb(0, 101, 255);
    }

    .scheme h3 span {
      display: none;
    }

    .phases {
      margin-left: 10px;
      border-right: 1px solid rgb(32, 34, 51);
    }

    .phases > div {
      flex: 1;
      border-left: 1px solid rgb(32, 34, 51);
      border-bottom: 1px solid rgb(32, 34, 51);
    }

    .info-box:first-child {
      margin-top: 35px;
    }

    .info-box:last-child {
      margin-bottom: 35px;
    }

    .symbol {
      display: inline-block;
      transform: rotate(90deg);
      font-style: normal;
      margin-top: -2px;
    }
  }

  ${vars.mobile.mediaQuery} {
    .headers {
      display: none;
    }

    .scheme {
      padding-bottom: 15px;
    }

    .closed .phases,
    .empty,
    .hidden {
      display: none;
    }

    .phases .visible {
      margin-bottom: 40px;
    }

    .scheme h3 {
      padding-bottom: 25px;
      font-size: 0.825rem;
      background: url(${up}) no-repeat right top 10px;
    }

    .closed h3 {
      background-image: url(${down});
    }

    .scheme h3 .border {
      width: 9px;
      height: 2px;
      margin-bottom: 3px;
      background: ${vars.colors.blue};
    }

    .scheme h3 span {
      width: 18px;
      height: 18px;
      margin-left: 9px;
      position: absolute;
      color: ${vars.colors.white};
      display: inline-block;
      font-size: 0.65rem;
      line-height: 18px;
      text-align: center;
      background: ${vars.colors.blue};
      border-radius: 50%;
    }
  }
`;

export { Root };
