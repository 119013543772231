import React from 'react';
import { Root } from './style';

import { useState } from '@hookstate/core';

import Scheme from './Scheme';

import left from 'assets/Common/Roadmap/left.svg';
import right from 'assets/Common/Roadmap/right.svg';

const Roadmap = ({ className, image, path, style }) => {
  const state = useState({
    phase: 1,
  });

  return (
    <Root>
      <div className="body-width paginator">
        <div
          style={{ backgroundImage: `url(${left})` }}
          onClick={() => state.phase.set(Math.max(state.phase.get() - 1, 1))}
        />
        <span>Phase {state.phase.get()}</span>
        <div
          style={{ backgroundImage: `url(${right})` }}
          onClick={() => state.phase.set(Math.min(state.phase.get() + 1, 4))}
        />
      </div>
      <Scheme
        state={state}
        domain="contract-innovation"
        headers={true}
        open={true}
        headline="contract"
      />
      <Scheme state={state} domain="products" headline="products" />
      <Scheme state={state} domain="exchange-listing" headline="exchanges" />
      <Scheme state={state} domain="partnerships" headline="partners" />
      <Scheme state={state} domain="staking-and-farming" headline="staking" />
      <Scheme state={state} domain="nfts" headline="hodl hands" />
      <Scheme state={state} domain="games" headline="mini-games" />
      <Scheme state={state} domain="betting" headline="gem fighter" />
      <Scheme state={state} domain="legal" headline="compliance" />
    </Root>
  );
};

export default Roadmap;
