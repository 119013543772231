import React from 'react';
import { Root } from './style';

import { useState } from '@hookstate/core';
import { useSiteData } from 'utils';
import camelCase from 'lodash/camelCase';

import InfoBox from 'components/Common/InfoBox';

const Scheme = ({ state, domain, headers, open, headline }) => {
  const { roadmap: page } = useSiteData().pages;

  const phases = [...Array(4).keys()].map((n) => n + 1);
  const lines = page[camelCase(domain)].split('\n');
  const topics = phases.reduce((t, i) => {
    t[i] = [];
    return t;
  }, {});

  let currentPhase, currentTopic;

  lines.forEach((line) => {
    let match = line.match(/==\s*(\d+)/);

    if (match) {
      currentPhase = parseInt(match[1]);
    } else {
      match = line.match(/(\d+)\s*-\s*(\d+)\s*(\+?)\s*(.*)/);
      if (match) {
        currentTopic = {
          start: parseInt(match[1]),
          end: 100 - parseInt(match[2]),
          done: match[3] === '+',
          name: match[4],
          info: '',
        };
        topics[currentPhase].push(currentTopic);
      } else if (line && currentTopic) {
        currentTopic.info += `\n${line}`;
      }
    }
  });

  const localState = useState({
    open,
  });

  return (
    <Root className="body-width">
      {headers ? (
        <div className="headers">
          {phases.map((n) => (
            <strong key={n}>Phase {n}</strong>
          ))}
        </div>
      ) : (
        ''
      )}
      <div
        className={`scheme ${topics[state.phase.get()].length ? '' : 'empty'} ${
          localState.open.get() ? '' : 'closed'
        }`}
      >
        <h3
          className={domain}
          onClick={() => localState.open.set(!localState.open.get())}
        >
          <div className="border" />
          {/* {domain.replace('-and-', ' & ').replace('-', ' ')} */}
          {headline === 'hodl hands' ? (
            <>
              hodl hands<em className="symbol">®</em>
            </>
          ) : (
            <>{headline}</>
          )}
          <span>{topics[state.phase.get()].length}</span>
        </h3>
        <div className="phases">
          {phases.map((n) => (
            <div
              key={n}
              className={n === state.phase.get() ? 'visible' : 'hidden'}
            >
              {topics[n].map((topic, i) => (
                <InfoBox
                  key={i}
                  className={topic.done ? 'done' : ''}
                  style={{
                    marginLeft: `calc(${topic.start}% + 4px)`,
                    marginRight: `calc(${topic.end}% + 4px)`,
                  }}
                  info={topic.info}
                  open={i === 0}
                >
                  {topic.name}
                </InfoBox>
              ))}
            </div>
          ))}
        </div>
      </div>
    </Root>
  );
};

export default Scheme;
